import copyText from "../../common.copyText";

/* prettier-ignore */
export default {
  ...copyText,
  addButtonLabel: "Add",
  addPairButtonLabel: "Add Pair",
  addLabelValueDialogue: 'Add "%VALUE%"',
  applyLabelValuesButton: "Apply Label Values",
  customLabelCardExistingLabel: "Existing Label Key-Value",
  customLabelCSVHeaderMatchKey: "Existing Label Key",
  customLabelCSVHeaderMatchValue: "Existing Label Value",
  customLabelCSVHeaderOutputKey: "Custom Label Key",
  customLabelCSVHeaderOutputValue: "Custom Label Value",
  customLabelsDocumentation: "Custom Label Documentation",
  customLabelsListHeaderOutput: "Custom Labels",
  customLabelsListMatchedKey: "Existing Label Key",
  customLabelsListMatchedValues: "Existing Label Values",
  customLabelsModalInstructionMatchKey: "Enter the key of the existing label that you want to target with a Custom Label. (eg. env)",
  customLabelsModalInstructionMatchValues: "Enter one or more values corresponding with the key in the previous field. (eg. dev, staging)",
  customLabelsModalInstructionOutput: "Enter one or more key-value pairs, each of which will either become a new Custom Label, or reference an existing one. (eg. cost-center: engineering)",
  customLabelsModalManagerPlaceholderKey: "Enter Key",
  customLabelsModalManagerPlaceholderValue: "Enter Values",
  errorCreatingLabelGroupingRuleMessage: "An error occurred while attempting to create the custom grouping. Please try again.",
  errorDeletingLabelGroupingRuleMessage: "An error occurred while attempting to delete the custom grouping. Please try again.",
  errorUpdatingLabelGroupingRuleMessage: "An error occurred while attempting to update the custom grouping. Please try again.",
  groupNameLabel: "Group Name:",
  keyOptionCreateDialogue: "Add key '%input%'",
  labelApplicatorLabelOneOf: "One of",
  labelGroupingRulesSectionInstructions: "Custom Groupings allow you to create a new grouping with two or more existing vendor labels. This can be used to resolve misspellings or name changes.",
  labelGroupingRulesSectionTitle: "Custom Groupings Manager",
  labelGroupingRulesTableHeader_inputs: "Labels",
  labelGroupingRulesTableHeader_outputKey: "Group Name",
  labelsLabel: "Labels:",
  learnMoreCaption: "Learn more about",
  learnMoreLink: "Custom Groupings",
  nameInputPlaceholder: "Name",
  newGroupingButtonLabel: "New Grouping",
  resetButtonLabel: "Reset",
  selectValueLabel: "Select Value...",
  successCreatingLabelGroupingRuleMessage: "The custom grouping has been successfully created.",
  successDeletingLabelGroupingRuleMessage: "The custom grouping has been successfully deleted.",
  successUpdatingLabelGroupingRuleMessage: "The custom grouping has been successfully updated.",
  tableHeaderCreatedAt: "Created At",
  tableHeaderCreatedBy: "Created By",
  tableHeaderUpdatedAt: "Updated At",
  tableHeaderUpdatedBy: "Updated By",
  valueOptionCreateDialogue: "Enter value '%input%'",
};
